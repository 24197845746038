rb-language-selector,
rb-popover {
  .language {
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;
  }

  .language-selector-link:not(:hover) {
    color: black;
  }
}
