@import '../imports';
@import '../mixins/scrollbar.scss';

rb-dropdown {
  .dropdown {
    position: absolute;
    text-align: left;
    box-sizing: border-box;
    top: 0;
    left: 0;
    font-size: $base-font-size;
    transition: transform $ease-in-out-quint $transition-duration-s;
    transform-origin: top;
    transform: scale(1, 0);
    z-index: 1501;
    display: none;
    background-color: white;

    &.open {
      display: block;
      transform: scale(1, 1);
    }
  }

  .dropdown-content {
    @include scrollbar();

    &.overflow-y {
      overflow-y: auto;
    }

    background-color: $color-white-paper-white;
    min-width: 100px;
  }
}

.rb-dropdown-item.rb-btn.rb-link {
  width: 100%;
  text-align: left;
}

.rb-dropdown-item,
.rb-dropdown-item.rb-btn.rb-link,
a.rb-dropdown-item {
  background-color: var(--plain__enabled__fill__default);
  color: var(--plain__enabled__front__default);
  display: block;
  cursor: pointer;
  padding: map-get($spacing-vertical, s) $default-spacing;
  font-size: map-get($font-sizes, smaller);

  &:hover,
  &.focus {
    background-color: var(--plain__enabled__fill__hovered);
    color: var(--plain__enabled__front__hovered);
    text-decoration: none;
  }

  /*selected*/
  &.active {
    background-color: var(--major-accent__enabled__fill__default);
    color: var(--major-accent__enabled__front__default);
  }

  /*select & hovered*/
  &.active:hover,
  &.active:focus {
    background-color: var(--major-accent__enabled__fill__hovered);
    color: var(--major-accent__enabled__front__hovered);
  }

  /*pressed down*/
  &:active {
    background-color: var(--plain__enabled__fill__pressed);
    color: var(--plain__enabled__front__pressed);
  }
}
