.rb-loader {
  position: relative;
  animation: spin 500ms 1 ease-in-out;
  transform-origin: 50% 50%;
}

.rb-loader .block1,
.rb-loader .block2 {
  width: 30%;
  height: 30%;
  position: absolute;
}

.rb-loader .block1 {
  background-color: #006249;
  top: 35%;
  left: 35%;
  animation: rb-loader-block1 2s infinite ease-in-out 500ms,
    rb-loader-colors 8s infinite linear 500ms alternate;
}

.rb-loader .block2 {
  background-color: #78be20;
  top: 35%;
  left: 35%;
  animation: rb-loader-block2 2s infinite ease-in-out 500ms,
    rb-loader-colors 8s infinite linear 1500ms alternate;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rb-loader-block1 {
  0% {
    top: 35%;
    left: 35%;
  }
  25% {
    top: 0;
    left: 35%;
  }
  50% {
    top: 35%;
    left: 35%;
  }
  75% {
    top: 35%;
    left: 0;
  }
  100% {
    top: 35%;
    left: 35%;
  }
}

@keyframes rb-loader-block2 {
  0% {
    top: 35%;
    left: 35%;
  }
  25% {
    top: 70%;
    left: 35%;
  }
  50% {
    top: 35%;
    left: 35%;
  }
  75% {
    top: 35%;
    left: 70%;
  }
  100% {
    top: 35%;
    left: 35%;
  }
}

@keyframes rb-loader-colors {
  0% {
    background-color: #006249;
  }
  12% {
    background-color: #006249;
  }
  13% {
    background-color: #78be20;
  }
  24% {
    background-color: #78be20;
  }
  25% {
    background-color: #00a8b0;
  }
  37% {
    background-color: #00a8b0;
  }
  38% {
    background-color: #008ecf;
  }
  49% {
    background-color: #008ecf;
  }
  50% {
    background-color: #005691;
  }
  62% {
    background-color: #005691;
  }
  63% {
    background-color: #50237f;
  }
  74% {
    background-color: #50237f;
  }
  75% {
    background-color: #b90276;
  }
  87% {
    background-color: #b90276;
  }
  88% {
    background-color: #e20015;
  }
  99% {
    background-color: #e20015;
  }
  100% {
    background-color: #006249;
  }
}
