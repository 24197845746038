rb-form-radio {
  .a-radio-button input.readonly ~ label,
  .a-radio-button input.readonly:checked ~ label {
    cursor: default;
    pointer-events: none;
  }

  .a-radio-button input.readonly:checked ~ label:before {
    background-color: var(--major-accent__enabled__fill__default);
  }
}
