$modal-spacing: 2rem;

rb-modal {
  &.hidden {
    display: none;
  }

  .modal-box {
    background: #fff;
    display: inline-block;
    min-width: 250px;
    position: relative;
    padding: 1rem;
    margin: 1rem;

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      line-height: 1rem;
      padding: 1rem;
    }

    .m-dialog {
      margin: -1rem;

      &.alert-dialog {
        margin-top: -1.375rem;
      }

      .dialog-close-btn {
        display: none;
      }
    }
  }

  &.middle {
    align-self: center;
  }

  &.top {
    margin-top: 3%;
    align-self: flex-start;
  }

  &.bottom {
    margin-bottom: 3%;
    align-self: flex-end;
  }
}

.rb-modal-backdrop {
  background: rgba(173, 173, 173, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 1500;
  justify-content: safe center;
  align-items: safe center;
  overflow: auto;

  &.large-fix {
    align-items: flex-start;
  }
}

body.rb-modal-open {
  overflow: hidden;

  .rb-modal-backdrop {
    display: flex;
  }
}

@supports (filter: blur(5px)) {
  body.rb-modal-open .rb-modal-frost {
    filter: blur(5px);
    min-height: 100%;
    height: 100%;
  }
}
