rb-tab-navigation {
  .a-tab-navigation__wrapper {
    display: flex;
    margin-bottom: 0;
    margin-top: 0;

    .a-tab-navigation {
      margin-bottom: 0;
      margin-top: 0;
      padding: 0 0.75rem;

      .a-tab-navigation__item {
        align-self: end;

        .a-tab-navigation__tab-content {
          transition-property: border-bottom-color;
          transition-duration: 300ms;
          transition-timing-function: ease-in-out;
        }
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;

      .title {
        margin: 0 0.75rem 11px 0.75rem;
        white-space: nowrap;
      }

      &__gradients {
        &:after,
        &:before {
          box-shadow: 0 0 30px 70px var(--background);
        }
      }
    }

    .navigation-menu-container {
      align-items: center;
    }

    .scroll-arrow-container,
    .navigation-menu-container {
      display: flex;
      border-bottom: 1px solid var(--bosch-gray-70);
      padding: 0 0.25rem;

      .scroll-icon {
        position: sticky;
        right: 0;
        font-size: 1.25rem;
        align-self: center;
        display: inline-block;
      }
    }

    .a-tab-navigation {
      border-bottom: 1px solid var(--bosch-gray-70);
    }

    .a-tab-navigation__tab {
      outline: 0;
    }

    .a-tab-navigation__icon {
      line-height: 1.25;
    }
  }

  .tab-content {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  &.-frozen-navigation {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;

    .a-tab-navigation__wrapper {
      flex: none;
    }

    .tab-content {
      flex: auto;
      overflow: auto;
    }
  }
}
