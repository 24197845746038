@import 'imports.scss';

@import 'frok/dist/frontend-kit';

@import 'base/icons.scss';
@import 'base/layout.scss';
@import 'base/basic.scss';
@import 'base/button.scss';
@import 'components/app-layout.component';
@import 'components/app-minimal-header.component';
@import 'components/app-minimal-footer.component';
@import 'components/app-side-navigation.component';
@import 'components/loading-spinner.component';
@import 'components/dropdown.component';
@import 'components/footer-nav.component';
@import 'components/full-header.component';
@import 'components/language-selector.component';
@import 'components/navigation.component';
@import 'components/popover.component';
@import 'components/breadcrumbs.component';
@import 'components/form-input.component';
@import 'components/form-radio.component';
@import 'components/form-checkbox.component';
@import 'components/form-chips-input.component';
@import 'components/form-select-list.component';
@import 'components/form-switch.component';
@import 'components/form-value-modifier.component';
@import 'components/modal.component';
@import 'components/search-result.component';
@import 'components/tab-navigation.component';
@import 'components/tab-panel.component';
@import 'components/callout.component';
@import 'components/teaser-panel.component';
@import 'components/board-item.component';
@import 'components/accordion.component';
@import 'components/progress-indicator.component';
@import 'components/table.component';
@import 'components/toast.component';
@import 'components/pagination.component';
@import 'flatpickr/dist/flatpickr.css';
@import 'overwrites/flatpickr';
@import 'overwrites/frok';

.supergraphic {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url($supergraphic-path--xs);
}

.supergraphic.bar {
  height: 14px;
}
