rb-form-value-modifier {
  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .a-arrow-padding {
    padding: 0 0.75rem;
  }

  .a-value-modificator .a-value-modificator__icon {
    vertical-align: bottom;
    padding-top: 0.7rem;
  }

  .a-arrow-padding:hover {
    background-color: var(--neutral__enabled__fill__hovered);
  }
}
