.rb-select-list {
  &.rb-form-select-list__disabled {
    .rb-ic {
      color: var(--neutral__disabled__front__default);
    }
  }

  .rb-select-list-wrapper {
    position: relative;
  }

  .rb-ic-custom {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }

  &.rb-form-select-list__labeled .with-icon {
    padding-left: 1rem;
  }

  &:not(.rb-form-select-list__labeled).rb-form-select-list__with-icon .with-icon {
    padding-left: 3rem;
  }
}

.rb-select-list-dropdown {
  .disabled {
    pointer-events: none;
  }

  .show-more {
    color: var(--minor-accent__enabled__front__default);
  }
}
