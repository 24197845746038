@use 'sass:math';
@import '../variables/sizes-spacing.scss';

@mixin sidenav-animation {
  transition-property: width;
  transition-duration: 250ms;
  transition-delay: 300ms;
  transition-timing-function: ease-in;
}

$nav-min-width: 48px;
$md-min: map-get($rb-grid-breakpoints, md);
$lg-min: map-get($rb-grid-breakpoints, lg);
$nav-width-xs-sm: 200px;
$nav-width-lg: 320px;
$ratio-md: math.div($nav-width-lg - $nav-width-xs-sm, $lg-min - $md-min);
$nav-width-md: calc($ratio-md * (100vw - $md-min) + $nav-width-xs-sm);

%scaled-width {
  width: $nav-width-xs-sm;
  @media (min-width: $md-min) {
    width: $nav-width-md;
  }
  @media (min-width: $lg-min) {
    width: $nav-width-lg;
  }
}

.--display-burger {
  %scaled-width {
    width: auto;
    max-width: min($nav-width-lg, calc(100vw - 2rem));
  }
}

.--body-scroll rb-app-side-navigation.m-side-navigation {
  height: auto;
}

rb-app-side-navigation.m-side-navigation {
  height: 100%;
  width: $nav-min-width;
  display: block;
  position: relative;
  overflow: visible;

  &.-open {
    width: $nav-min-width;

    &.-pinned,
    &:not(.-collapsible) {
      @extend %scaled-width;
    }
  }

  &:not(.-open) .m-side-navigation__:not(.-burger-sidenav) div {
    pointer-events: none;
  }

  .placeholder {
    content: '\00A0 ';
    width: $nav-min-width;
    height: 100%;
  }

  &.-pinned .placeholder {
    @extend %scaled-width;
  }

  &.-open:not(.-pinned) {
    .m-side-navigation__ {
      z-index: 10000;
    }
  }

  .m-side-navigation__ {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: inherit;
    color: inherit;
    overflow-x: hidden;
    @extend %scaled-width;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    &.-burger-sidenav {
      @include sidenav-animation;
    }

    &.-floating-nav {
      width: $nav-min-width;
      @include sidenav-animation;

      &:not(.-burger-sidenav):hover {
        @extend %scaled-width;
      }
    }

    .m-side-navigation__menuItem.-open {
      height: auto;
    }

    .m-side-navigation__icon-placeholder {
      width: $nav-min-width;
      flex: none;
    }

    .m-side-navigation__header,
    .m-side-navigation__footer {
      flex: none;
      overflow-x: hidden;
      margin-bottom: 0;
      @extend %scaled-width;
    }

    .m-side-navigation__header {
      border-bottom: 1px solid var(--bosch-gray-20);
    }

    .m-side-navigation__footer.-with-content {
      margin-top: map-get($spacing-vertical, xs);
    }

    .m-side-navigation__menu {
      flex: auto;
      overflow: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      direction: rtl;
      overflow-x: hidden;
      @extend %scaled-width;

      &.-open {
        overflow-y: auto;
      }

      ul {
        direction: ltr;
      }

      &.-scrollingGutterDisplayed .m-side-navigation__menuItems .m-side-navigation__menuItem {
        .m-side-navigation__link,
        .m-side-navigation__group {
          padding-left: calc(0.75rem - $nav-scrollbar-width);
        }
      }
    }

    .m-side-navigation__header__label,
    .m-side-navigation__label {
      display: block;
      word-break: unset;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 1;
      padding: 0;
    }

    .m-side-navigation__header__label {
      padding: 0.5rem;
    }

    .m-side-navigation__menuItems .m-side-navigation__group > .a-icon:last-child {
      margin-left: inherit;
    }

    &.-open .m-side-navigation__menuItem.-open .m-side-navigation__menuSubitems {
      height: auto;
    }
  }

  &.-collapsible.-forced-close {
    width: $nav-min-width;

    .m-side-navigation__ {
      @extend .m-side-navigation__;
      width: $nav-min-width;
    }
  }

  .m-side-navigation__link {
    transition-property: background-color;
    transition-duration: 150ms;

    &--height {
      height: 100% !important;
    }
  }

  .m-side-navigation__align-center {
    align-items: center !important;
  }
}

rb-app-side-navigation:not(.-open) .-subitem-selected .m-side-navigation__group,
rb-app-side-navigation.-open .-subitem-selected:not(.-open) .m-side-navigation__group {
  background-color: var(--major-accent__enabled__fill__default);

  &:hover {
    background-color: var(--major-accent__enabled__fill__hovered);
  }
}

.-subitem-selected.-open .m-side-navigation__group {
  background-color: var(--plain__enabled__fill__pressed);
}
