rb-form-chips-input {
  .a-text-field {
    height: auto;
  }

  .label-spacing {
    height: 1.5rem;
    width: 100%;
    position: relative;
    margin-bottom: -0.5rem;
  }

  .chips-form-container {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--neutral__enabled__fill__default);
    border: 0;
    border-bottom: 0.0625rem solid var(--neutral__enabled__front__default);

    &:hover {
      background-color: var(--neutral__enabled__fill__hovered);
    }

    &:active {
      background-color: var(--neutral__enabled__fill__pressed);
    }

    &.focus {
      background-color: var(--neutral__focused__fill__default);
      border-bottom-color: var(--neutral__focused__front__default);
      outline: none;
    }
  }

  .chips {
    &.has-chips {
      margin-top: 0.5rem;
      padding-left: 0;
      padding-right: 0;
      margin-left: 1rem;
      width: auto;
    }

    .rb-chip:last-child {
      margin-right: 0 !important;
    }
  }

  .a-chip {
    background: var(--bosch-gray-70);
    height: 1.5rem;
    margin-bottom: 0.5rem;

    &:hover {
      background: var(--bosch-gray-60);
    }
  }

  .a-chip.-selected .a-chip__close {
    &:before {
      background: #fff;
    }

    &:after {
      background: #fff;
    }
  }

  .a-chip.disabled {
    pointer-events: none;
    background-color: var(--bosch-gray-80);
    color: var(--bosch-gray-50);

    .a-chip__close {
      &:before {
        background: var(--bosch-gray-50);
      }

      &:after {
        background: var(--bosch-gray-50);
      }
    }
  }

  .chips-input {
    background: transparent;
    border-bottom: 0;
    flex-grow: 1;
    flex-flow: wrap;
    padding-top: 0.25rem;
    padding-bottom: 0;
    height: 2rem;

    &:hover {
      background-color: transparent;
    }
  }

  .no-label .chips-form-container {
    padding-top: 0;
  }
}
