@import '../imports';
@import '../mixins/scrollbar.scss';

$arrow-width: 22px;
$arrow-half-width: $arrow-width * 0.5;

rb-popover {
  .rb-popover {
    background-color: $color-white-paper-white;
    position: absolute;
    text-align: left;
    box-sizing: border-box;
    display: none;
    top: 0;
    left: 0;
    font-size: $base-font-size;
    z-index: 1501;
    &.open {
      display: block;
      transform: scale(1, 1);
    }
    &.bottom,
    &.top {
      margin: 0 2rem;
    }
  }
  .rb-popover-content {
    @include scrollbar();
    min-width: 100px;

    &.overflow-y {
      overflow-y: auto;
    }
  }
  .rb-popover-content-text {
    min-width: 100px;
    padding: 0.25rem;
  }
  .rb-popover-arrow {
    transform: rotate(45deg);
    width: $arrow-width;
    height: $arrow-width;
    background-color: $color-white-paper-white;
    position: absolute;
    z-index: -2;
    pointer-events: none;

    top: -$arrow-half-width;
    left: 50%;
    &.bottom {
      top: auto;
      bottom: -$arrow-half-width;
    }
    &.left {
      left: -$arrow-half-width;
    }
    &.right {
      right: -$arrow-half-width;
      left: auto;
    }
  }

  .rb-popover,
  .rb-popover-arrow {
    box-shadow: 0 0 0.5rem 0.1rem var(--shadow-fill);
  }

  .rb-popover-background {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $color-white-paper-white;
  }

  ul {
    margin-bottom: 0;
  }

  .a-menu-item__link {
    column-gap: 0;
    white-space: nowrap;
  }
}
