.--body-scroll rb-app-minimal-footer .o-footer.-minimal .o-footer__bottom {
  overflow-x: visible;
}

rb-app-minimal-footer .o-footer.-minimal {
  .o-footer__bottom {
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    padding: 0.5rem 0;
    justify-content: flex-start;
    background-color: white;

    .o-footer__links,
    .o-footer__copyright {
      order: unset;
      flex: none;
      white-space: nowrap;
    }

    .o-footer__links .a-link {
      white-space: nowrap;
    }

    .o-footer__separator {
      min-width: 1rem;
      max-width: 3rem;
      flex: auto;
    }

    .o-footer__expander {
      flex: auto;
    }
  }
}
