@import '../mixins/scrollbar';

$table-bg-transition-time: 150ms;

//
// Tables-reset css
//

table {
  border-collapse: collapse; // Prevent double borders
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: black;
  text-align: left;
  caption-side: bottom;
}

th {
  // Matches default `<td>` alignment by inheriting from the `<body>`, or the
  // closest parent with a set `text-align`.
  text-align: inherit;
  user-select: none;
}

.ic-placeholder {
  td:last-child:after {
    display: inline-block;
    vertical-align: bottom;
    width: 24px;
    height: 24px;
    font-size: 24px;
    font-family: 'Bosch-Icon';
    content: '';
  }
}

.table-danger--ic {
  .table-ic:after {
    content: '\eb08' !important;
  }
}

.table-warning--ic {
  .table-ic:after {
    content: '\eb0c' !important;
  }
}

.table-success--ic {
  .table-ic:after {
    content: '\eb0a' !important;
  }
}

.table-success,
.m-table td.table-success {
  box-shadow: inset 5px 0 0 0 var(--bosch-green-50);
  transition: background-color $table-bg-transition-time;
}

.table-warning,
.m-table td.table-warning {
  box-shadow: inset 5px 0 0 0 var(--bosch-yellow-85);
  transition: background-color $table-bg-transition-time;
}

.table-danger,
.m-table td.table-danger {
  box-shadow: inset 5px 0 0 0 var(--bosch-red-55);
  transition: background-color $table-bg-transition-time;
}

.table-info,
.m-table .table-danger th,
.m-table .table-danger td {
  box-shadow: inset 5px 0 0 0 var(--bosch-blue-50);
  transition: background-color $table-bg-transition-time;
}

.rb-table {
  > div {
    @include scrollbar();
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .loading tbody {
    filter: opacity(0.5);
  }

  rb-progress-indicator {
    .progress-container {
      height: 4px;
    }
  }
}

.m-table {
  width: 100%;
  overflow-y: auto;
  line-height: 1rem;

  &.fixed {
    table-layout: fixed;
  }

  tr.active td {
    background-color: var(--bosch-gray-90);
  }

  &.active tr:hover:not(.active) {
    cursor: pointer;

    td {
      background-color: var(--bosch-blue-90) !important;
    }
  }

  td:last-child {
    white-space: nowrap;
  }

  th {
    background-color: var(--bosch-gray-90);
    border-bottom-color: var(--bosch-gray-75);
    font-weight: 700;
    top: 0;
    position: sticky;
    z-index: 100;
  }

  td {
    transition: background-color 300ms;
  }

  td,
  th {
    line-height: 1;
  }

  a {
    vertical-align: middle;
  }

  .rb-ic {
    font-size: 1.4rem;
    line-height: 1;
    display: inline-block;
  }

  .rb-btn.rb-link {
    margin: 0 1rem 0 0;
    padding: 0;
  }

  rb-form-select {
    display: inline-block;

    .m-form-field {
      margin-bottom: 0;
    }

    .a-dropdown {
      height: 1.5rem;

      &:after {
        top: 0;
      }

      select {
        background-color: transparent;
        padding-left: 0;
        border-bottom: 0;
        height: 1.5rem;

        &:focus {
          outline: none;
        }
      }
    }
  }

  @keyframes loading {
    40% {
      background-position: 150px 0;
    }
    100% {
      background-position: 150px 0;
    }
  }

  .loading {
    position: relative;

    &.loading--dark .loading-bar {
      background-color: var(--bosch-gray-40);
    }

    &.loading--dark:after {
      background-image: radial-gradient(
        circle,
        rgba(255, 255, 255, 0),
        rgba(224, 226, 229, 0.5) 0%,
        rgba(255, 255, 255, 0) 50%
      );
    }

    &.loading--light .loading-bar {
      background-color: var(--bosch-gray-90);
    }

    &.loading--light:after {
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0) 80%
      );
    }

    &:after {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 26px;
      background-size: 200px 24px;
      background-position: -100px 0;
      background-repeat: no-repeat;
      animation: loading 1.2s infinite;
    }

    .loading-bar {
      height: 16px;
      border-radius: 6px;
      width: 75%;
    }
  }
}
