@import '../imports';

$brand-identifier-font-weight: map-get($font-weights, light);
$brand-identifier-line-height: map-get($line-heights, medium);
$brand-identifier-font-size: map-get($font-sizes, small);
$brand-identifier-font-size--sm: map-get($heading-font-sizes, h4-sm);
$brand-identifier-font-size--md: map-get($heading-font-sizes, h4-md);

rb-full-header {
  .header {
    background-color: $color-white-paper-white;
    border-bottom: 1px solid $color-gray-mercury;
  }

  .header-placeholder {
    display: none;
    &.sticky {
      display: block;
    }
  }

  .header-content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 200px 2fr 1fr;
    grid-template-columns: 200px 2fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .logo {
    /* autoprefixer: off */
    grid-column: 1/2;
    -ms-grid-column: 1;
    grid-row: 1/3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    img {
      width: 100px;
      height: auto;
    }
  }

  .small-nav {
    display: none;
    padding: 0 $default-spacing;
  }

  .meta-nav {
    /* autoprefixer: off */
    grid-column: 2/4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-row: 1/2;
    -ms-grid-row: 1;
    text-align: right;
    padding: 0 $default-spacing 0 $default-spacing;
  }

  .sub-brand {
    /* autoprefixer: off */
    grid-column: 2/4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    grid-row: 2/3;
    -ms-grid-row: 2;
    text-align: right;
    padding: 0 $default-spacing;
  }

  .sub-brand-content {
    font-weight: $brand-identifier-font-weight;
    font-size: $brand-identifier-font-size;
    line-height: $brand-identifier-line-height;
  }

  .action-nav {
    /* autoprefixer: off */
    grid-column: 3/4;
    -ms-grid-column: 3;
    grid-row: 3/4;
    -ms-grid-row: 3;
    text-align: right;
    padding: 0 $default-spacing;
  }

  .main-nav {
    /* autoprefixer: off */
    grid-column: 1/3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-row: 3/4;
    -ms-grid-row: 3;
    padding: 0 $default-spacing;
  }

  .sub-nav {
    /* autoprefixer: off */
    grid-column: 1/4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-row: 4/5;
    -ms-grid-row: 4;
    padding: 0.2em $default-spacing 0 $default-spacing;
  }
  .sub-nav::before {
    border-bottom: 1px solid $color-gray-mercury;
    position: absolute;
    left: 0;
    width: 100%;
    content: '';
    margin-top: -0.2em;
  }

  .toggler {
    width: 3em;
    height: 3em;
    border: 0;
    background: transparent;

    .bar {
      display: block;
      height: 1px;
      background-color: $color-black-pitch-dark;
      margin: 0.5em 0;
      transition: all $ease-in-out-quint $transition-duration-s;
    }

    &.open {
      .bar {
        margin: 0;
      }
      .bar:nth-child(1) {
        transform: rotate(45deg);
      }

      .bar:nth-child(2) {
        transform: rotate(-45deg);
      }
      .bar:nth-child(3) {
        opacity: 0;
      }
    }
  }

  .header.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1100;
    .logo,
    .meta-nav,
    .sub-brand {
      display: none;
    }
    .supergraphic.bar {
      height: 8px;
    }
  }

  .main-nav,
  .sub-nav,
  .meta-nav {
    transform-origin: top;

    &.open {
      height: auto;
      transform: scale(1, 1);
      transition: transform $ease-in-out-quint $transition-duration-s;
      background-color: $color-white-paper-white;
    }
  }
}

// larger sm
@media (min-width: map-get($rb-grid-breakpoints, sm)) {
  rb-full-header {
    .sub-brand-content {
      font-size: $brand-identifier-font-size--sm;
    }
    .logo img {
      width: 140px;
      height: auto;
    }
  }
}

// larger md
@media (min-width: map-get($rb-grid-breakpoints, md)) {
  rb-full-header {
    .sub-brand-content {
      font-size: $brand-identifier-font-size--md;
    }
    .logo img {
      width: 170px;
      height: auto;
    }
  }
}

// smaller md
@media (max-width: $navigation-break) {
  rb-full-header {
    .content {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      -ms-grid-rows: auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto;
    }
    .logo {
      /* autoprefixer: off */
      grid-column: 1/2;
      -ms-grid-column: 1;
      grid-row: 1/2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
    }
    .action-nav {
      /* autoprefixer: off */
      grid-column: 2/4;
      -ms-grid-column: 2;
      -ms-grid-column-span: 2;
      grid-row: 2/3;
      -ms-grid-row: 2;
    }
    .sub-brand {
      /* autoprefixer: off */
      grid-column: 2/4;
      -ms-grid-column: 2;
      -ms-grid-column-span: 2;
      grid-row: 1/2;
      -ms-grid-row: 1;
      padding: $default-spacing;
    }
    .small-nav {
      display: block;
      /* autoprefixer: off */
      grid-column: 1/2;
      -ms-grid-column: 1;
      grid-row: 2/3;
      -ms-grid-row: 2;
    }
    .main-nav {
      /* autoprefixer: off */
      grid-column: 1/4;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      grid-row: 3/4;
      -ms-grid-row: 3;
      border-top: 1px solid $color-gray-mercury;
      border-bottom: 1px solid $color-gray-mercury;
    }
    .sub-nav {
      /* autoprefixer: off */
      grid-column: 1/4;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      grid-row: 4/5;
      -ms-grid-row: 4;
      text-align: left;
    }
    .sub-nav::before {
      display: none;
    }
    .meta-nav {
      /* autoprefixer: off */
      grid-column: 1/4;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
      grid-row: 5/6;
      -ms-grid-row: 5;
      text-align: left;
      border-bottom: 1px solid $color-gray-mercury;
    }

    .main-nav,
    .sub-nav,
    .meta-nav {
      padding: 0;
      transform: scale(1, 0);
      height: 0;
    }

    .header.sticky .meta-nav.open {
      display: block;
    }
  }
}
