rb-toast {
  display: flex;
  align-items: flex-end;
  width: 100%;

  :not(:first-child) {
    margin-bottom: 8px;
  }

  .simple-toast {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .toast-label {
    font-family: BoschSans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
  }

  .toast-icon {
    display: flex;
    align-items: center;
    height: 24px;
    margin-right: 8px;
  }
}

.toaster-overlay {
  z-index: 99999;
  display: none;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  overflow: auto;
  bottom: 0;
  width: calc(100vw - 4rem);
}

.toaster-active {
  display: block;
}
