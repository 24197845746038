.board-item {
  position: relative;
  border-top: 1px solid $color-gray-mercury;
  padding: map-get($spacing-vertical, m) 0;

  .board-item-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .board-item-icon {
      margin-right: 2rem;
    }
    .board-item-content {
      margin-bottom: map-get($spacing-vertical, m);
      flex-grow: 1;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: map-get($heading-font-sizes, h4-sm);
      margin-bottom: map-get($spacing-vertical, xs);
    }
  }

  .board-item-counts {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    .board-item-count {
      flex-grow: 1;
    }
  }

  .board-item-status {
    position: absolute;
    top: 0;
    right: 0;
    border-top: 50px solid $color-gray-alto;
    border-left: 50px solid transparent;

    .board-item-status-icon {
      position: absolute;
      top: -42px;
      right: 8px;
      font-size: 0.8rem;
    }
  }
}

rb-board-item {
  &.rb-success {
    .board-item .board-item-status {
      border-top: 50px solid $color-green-lima;
      color: $color-white-paper-white;
      fill: $color-white-paper-white;
    }
  }
  &.rb-danger {
    .board-item .board-item-status {
      border-top: 50px solid $color-red-monza;
      color: $color-white-paper-white;
      fill: $color-white-paper-white;
    }
  }
  &.rb-warning {
    .board-item .board-item-status {
      border-top: 50px solid $color-yellow-sunlight;
      color: $color-white-paper-white;
      fill: $color-white-paper-white;
    }
  }
  &.rb-primary {
    .board-item .board-item-status {
      border-top: 50px solid $brand-primary;
      color: $color-white-paper-white;
      fill: $color-white-paper-white;
    }
  }
}

// larger sm
@media (min-width: map-get($rb-grid-breakpoints, sm)) {
  .board-item {
    .board-item-counts {
      .board-item-count {
        flex-grow: 0;
        margin-right: 2em;
      }
    }
  }
}

// larger md
@media (min-width: map-get($rb-grid-breakpoints, md)) {
  .board-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 40px;

    .board-item-body {
      flex-grow: 2;
      flex-basis: 0;
      padding-right: 2em;
      .board-item-content {
        margin-bottom: 0;
      }
    }

    .board-item-counts {
      flex-grow: 1;
      flex-basis: 0;
      .board-item-count {
        flex-grow: 1;
        flex-basis: 0;
        margin: 0 2em;
      }
    }
  }
}
