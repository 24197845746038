.pagination-container {
  display: flex;

  &.disabled {
    pointer-events: none;
    color: var(--neutral__disabled__front__default);

    a,
    .rb-btn.rb-link {
      color: var(--neutral__disabled__front__default);
    }
  }

  form {
    display: inline-block;
  }

  .pageNum {
    width: 4rem;
    padding: 0.1rem;

    background-color: var(--neutral__enabled__fill__default);
    border: 0;
    border-bottom: rem(1px) solid var(--neutral__enabled__front__default);
    color: var(--neutral__enabled__front__default);

    &:hover {
      background-color: var(--neutral__enabled__fill__hovered);
    }

    &:active {
      background-color: var(--neutral__enabled__fill__pressed);
    }

    &:focus {
      background-color: var(--neutral__focused__fill__default);
      border-bottom-color: var(--neutral__focused__front__default);
      outline: none;
    }
  }
}
