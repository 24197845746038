.rb-app-layout-main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.--body-scroll {
    overflow: visible;
    height: auto;
    width: auto;

    .rb-app-layout-main__content {
      overflow: visible;
      position: relative;
    }
  }

  &.--display-burger {
    &:not(.--burger-selected) rb-app-side-navigation {
      display: none;
    }

    .rb-app-layout-main__navigation-wrapper {
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: column;

      .spacer-for-burger {
        flex: none;
      }
    }
  }

  .rb-app-layout-main__supergraphic {
    @extend .o-minimal-header__supergraphic;
    position: unset; /* Avoid "position: absolute" as much as possible */
    height: $supergraphic-height;
    flex: none;
  }

  .rb-app-layout-main__content {
    flex: auto;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
  }

  * {
    @include contentScrollbar;
  }

  .m-side-navigation__menu,
  .o-footer__bottom,
  .o-minimal-header__basic-actions {
    @include navScrollbar;
  }

  /* special hover for contrasting background */
  .-contrast {
    @include contrastScrollbar;
  }
}

.rb-app-layout-content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: 0;

  .rb-app-layout-content {
    flex-grow: 1;
    word-wrap: break-word;
    overflow: auto;
  }
}
