a:hover,
a:visited:hover {
  cursor: pointer;
  text-decoration: none;
}

.a-menu-item__link {
  transition: all 150ms;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;

  &:active {
    color: var(--bosch-blue-55);
  }
}

.a-menu-item__link--disabled,
.a-menu-item__link:hover.a-menu-item__link--disabled {
  cursor: default;
  background-color: unset;
  color: var(--bosch-gray-50);
}

.a-menu-item__link--active {
  background-color: var(--bosch-gray-95);
  color: var(--plain__enabled__front__hovered);

  &:hover {
    background-color: var(--bosch-gray-85);
  }
}

.link-list {
  margin: 0;

  .link-list-item,
  li {
    a {
      display: block;
      padding: map-get($spacing-vertical, s) $default-spacing;
      font-size: map-get($font-sizes, smaller);
      color: $color-gray-solid;

      &.active {
        color: $color-black-pitch-dark;
      }

      &:hover {
        background-color: $color-gray-alabaster;
        color: $navigation-link-color--active;
        text-decoration: none;
      }
    }
  }
}

.a-dropdown select {
  border-bottom: rem(1px) solid var(--neutral__enabled__front__default);
}

.rb-select-btn,
.a-dropdown select {
  &:hover {
    cursor: pointer;
  }
}

.a-value-modificator {
  margin: 0;
  width: auto;

  &.-disabled {
    pointer-events: none;
  }

  &.-disabled > label {
    color: var(--neutral__disabled__front__default);
  }

  &.rb-ic {
    padding: 0;
  }
}

.rb-btn .rb-ic + span {
  text-decoration: none;
}

.a-link--primary a,
.a-link--simple a {
  display: flex;
}

.a-link:not(.-icon, .a-link--button, .a-link--button-secondary, .a-link--integrated, .-disabled) a {
  text-decoration: none;
}

.a-link--primary a:hover,
.a-link--primary a:visited:hover,
.a-link--simple a:hover,
.a-link--simple a:visited:hover {
  text-decoration: underline;
}

.a-link--primary a:after {
  content: '';
  border-top: 0.0625rem solid;
  border-right: 0.0625rem solid;
  position: relative;
  width: 0.5em;
  height: 0.5em;
  margin-left: 0.25em;
  transform: translateY(0.465em) rotate(45deg);
}

.o-header__breadcrumbs li:last-child a:after {
  content: none;
}

.-floating,
.a-tooltip,
[data-tooltip]:hover:before {
  box-shadow: 0 0 0.5rem 0.1rem #00000040;
  box-shadow: 0 0 0.5rem 0.1rem var(--shadow-fill);
}
