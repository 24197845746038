
rb-breadcrumbs .breadcrumbs,
rb-simple-breadcrumbs {
  .o-header__breadcrumbs {
    a {
      .breadcrumbs-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: var(--breadcrumb-entry-max-width);
        white-space: nowrap;
      }
    }
  }
}

rb-breadcrumbs {
  .breadcrumbs {
    padding-left: 16px;
    display: flex;
    align-items: center;
  }

  .breadcrumbs-container {
    display: flex;
    justify-content: space-between;
  }

  .breadcrumbs-right {
    font-size: map-get($font-sizes, smallest);
    padding: map-get($spacing-vertical, s) $default-spacing;
  }
}


.breadcrumbs-popover {
  width: 192px;

  li {
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    padding: 0;
    font-size: 0.9rem;

    &:hover {
      background-color: var(--bosch-gray-90);
    }

    &:active {
      background-color: var(--bosch-gray-80);
    }

    a {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--integrated__enabled__front__default);
      width: 100%;
      padding: 8px 12px;
    }
  }
}
