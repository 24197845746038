@import '../imports';

@keyframes progress-animation {
  from {
    left: -150%;
  }
  to {
    left: 0%;
  }
}

$color-blue-light-2: #40aadb;

rb-progress-indicator {
  .progress-current {
    width: 0;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .noanim .progress-current {
    transition: width 1s linear;
  }

  .progress-gradient {
    height: 100%;
    width: 250%;
    background-image: repeating-linear-gradient(
      to right,
      $color-blue-light-2 0%,
      $color-blue-light-lochmara 10%,
      $color-blue-dark-bahama-blue 20%,
      $color-blue-dark-bahama-blue 40%,
      $color-blue-light-lochmara 50%,
      $color-blue-light-2 60%
    );
    position: absolute;
    animation: progress-animation 3s linear infinite;
  }

  .noanim .progress-gradient {
    animation: none;
    left: -50%;
    transition: left 1s linear;
  }

  .progress-container {
    height: 0.75rem;
    position: relative;
    overflow: hidden;
  }

  .progress-background {
    height: 100%;
    background: var(--bosch-gray-80);
  }

  &.large .progress-container {
    height: 1.25rem;
  }

  &.small .progress-container {
    height: 0.5rem;
  }

  &.tiny .progress-container {
    height: 2px;
  }
}
