.rb-btn,
.rb-btn:visited {
  background-color: var(--minor-accent__enabled__fill__default);
  color: var(--minor-accent__enabled__front__default);
  border: 1px solid var(--minor-accent__enabled__front__default);
  border-radius: 0;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  line-height: 40px;
  padding: 0 20px;
  font-size: rem(14px);

  .rb-ic {
    font-size: 1.5em;
    vertical-align: middle;
    line-height: inherit;
    margin: -0.3em 0;
  }

  .rb-ic:first-child {
    margin-left: -5px;
  }

  .rb-ic:last-child {
    margin-right: -5px;
  }

  &:disabled,
  &.disabled {
    background-color: var(--minor-accent__disabled__fill__default);
    color: var(--minor-accent__disabled__front__default);
    border-color: var(--minor-accent__disabled__front__default);
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      background-color: var(--minor-accent__enabled__fill__hovered);
      color: var(--minor-accent__enabled__front__hovered);
      border-color: var(--minor-accent__enabled__front__hovered);
      outline: none;
    }
  }

  &.rb-primary {
    background-color: var(--major-accent__enabled__fill__default);
    color: var(--major-accent__enabled__front__default);

    &:disabled,
    &.disabled {
      background-color: var(--major-accent__disabled__fill__default);
      color: var(--major-accent__disabled__front__default);
    }

    &:not(:disabled):not(.disabled) {
      &:hover,
      &:active,
      &:focus {
        border: 1px solid var(--major-accent__enabled__fill__hovered);
        background-color: var(--major-accent__enabled__fill__hovered);
        color: var(--major-accent__enabled__front__default);
      }
    }
  }

  &.rb-danger {
    border-color: var(--bosch-red-50);
    color: var(--bosch-red-50);

    &:disabled,
    &.disabled {
      color: var(--minor-accent__disabled__front__default);
      border-color: var(--minor-accent__disabled__front__default);
    }

    &:not(:disabled):not(.disabled) {
      &:hover,
      &:active,
      &:focus {
        background-color: var(--bosch-red-90);
        color: var(--bosch-red-50);
        border-color: var(--bosch-red-50);
      }
    }
  }

  &.rb-link,
  &.rb-passive-link {
    color: var(--minor-accent__enabled__front__default);
    border: none;
    transition: none;
    background: none;
    line-height: inherit;
    vertical-align: inherit;
    padding: 0 10px;
    font-size: inherit;
    font-weight: inherit;

    &:disabled,
    &.disabled {
      pointer-events: none;
      color: var(--minor-accent__disabled__front__default);
    }

    &:not(:disabled):not(.disabled) {
      &:hover {
        color: var(--minor-accent__enabled__front__hovered);
        cursor: pointer;
        background: none;
      }

      &:active,
      &:focus {
        color: var(--minor-accent__enabled__front__pressed);
        cursor: pointer;
        background: none;
      }
    }
  }

  &.rb-passive-link {
    color: var(--bosch-black);
    &:not(:disabled):not(.disabled) {
      &:hover {
        color: var(--minor-accent__enabled__front__hovered);
      }

      &:active,
      &:focus {
        color: var(--minor-accent__enabled__front__pressed);
      }
    }
  }

  &.rb-regular {
    line-height: 48px;
    padding: 0 0.75rem;
    font-size: rem(16px);
  }

  &.rb-smaller {
    line-height: 40px;
    padding: 0 20px;
    font-size: rem(14px);
  }

  &.rb-tiny {
    line-height: 30px;
    padding: 0 10px;
    font-size: rem(12px);
  }

  &.rb-full-width {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
