@mixin scrollbar() {
  // For Firefox (V 115.9.1esr), the possibilities are quite reduced...
  // Do not include them because they overwrite webkit styles in Chrome
  // (Chrome for Windows, V 122.0.6261.112)
  //  scrollbar-width: thin;
  //  scrollbar-color: var(--bosch-blue-50) var(--bosch-gray-90);

  // For Google Chrome
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--minor-accent__enabled__front__default);
    &:hover {
      background: var(--minor-accent__enabled__front__hovered);
    }

    &:active {
      background: var(--minor-accent__enabled__front__pressed);
    }
    border-radius: 0;
    border: 3px solid var(--bosch-gray-95);
  }

  &::-webkit-scrollbar-track {
    background: var(--bosch-gray-95);
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: var(--minor-accent__enabled__front__default);
    scrollbar-track-color: var(--bosch-gray-90);
  }
}

@mixin contentScrollbar {
  // For Firefox (V 115.9.1esr), the possibilities are quite reduced...
  // Do not include them because they overwrite webkit styles in Chrome
  // (Chrome for Windows, V 122.0.6261.112)
  // scrollbar-width: thin;
  // scrollbar-color: #888 transparent;

  &::-webkit-scrollbar {
    width: $content-scrollbar-width;
    height: $content-scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;

    &:hover {
      background: #666;
    }

    &:active {
      background: #444;
    }
  }
}

@mixin contrastScrollbar {
  &::-webkit-scrollbar-thumb {
    background: #888;

    &:hover {
      background: #aaa;
    }

    &:active {
      background: #ccc;
    }
  }
}

@mixin navScrollbar {
  @include contentScrollbar;
  &::-webkit-scrollbar {
    width: $nav-scrollbar-width;
    height: $nav-scrollbar-width;
  }
}
