//
// Colours
// ==========================================================================
$callout-text-color--inverted: $color-white-paper-white;
$callout-background-color--inverted: rgba(255, 255, 255, 0.15);

$callout-text-color--error: $color-red-monza;
$callout-background-color--error: rgba($color-red-monza, 0.15);

/* stylelint-disable */
// Disables as stylelint throws error because colors are the same, just
// with different opacity
$callout-text-color--warning: #fbaf16;
$callout-background-color--warning: rgba(251, 175, 22, 0.15);
/* stylelint-enable */

//
// Spacing
// ==========================================================================
$callout-padding: map-get($spacing-vertical, m);
$callout-margin-bottom: map-get($spacing-vertical, m);

$callout-icon-margin-right: map-get($spacing-vertical, m);

$callout-close-icon-margin-left: map-get($spacing-vertical, l);
//
// Font Sizing/Weights
// ==========================================================================
$callout-text-font-size: map-get($font-sizes, smaller);
$callout-text-font-weight: map-get($font-weights, medium);

rb-callout {
  &.no-margin > div {
    margin: 0;
  }
}

.rb-callout {
  display: flex;
  padding: $callout-padding;
  margin-bottom: $callout-margin-bottom;
  font-size: $callout-text-font-size;
  font-weight: $callout-text-font-weight;
  font-family: $base-font-family--bold;
  align-items: stretch;
  word-break: break-word;
  white-space: normal;

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }

  p,
  a {
    font-size: $callout-text-font-size;
    font-weight: $callout-text-font-weight;
  }

  .icon-container {
    display: flex;
    align-items: center;
  }

  .rb-ic {
    flex: none;
    display: inline-block;
    font-size: 2.2em;
    margin-right: $callout-icon-margin-right;
    &.rb-ic-info {
      font-size: 1.2em;
      padding: 0.3em;
      border-radius: 100%;
      color: $color-bosch-light-blue-w75;
      background: $color-bosch-light-blue;
    }
  }

  .ng-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .close-button {
    margin-left: $callout-close-icon-margin-left;
    padding: 0;
    color: inherit;

    .rb-ic {
      padding: 0px;
      font-size: 2em;
    }
  }
}

// Apparently uselessly specific, but we need it to overwrite the button style
$specific-hover-selector: '.rb-btn.rb-link.close-button:hover';

.rb-callout-error {
  color: $color-bosch-red;
  background-color: adjust-color($color-bosch-red, $alpha: -0.85);

  #{$specific-hover-selector} {
    color: $color-red-monza--pastell;
  }
}

.rb-callout-warning {
  color: $color-bosch-yellow;
  background-color: adjust-color($color-bosch-yellow, $alpha: -0.85);

  #{$specific-hover-selector} {
    color: $color-bosch-yellow-w50;
  }
}

.rb-callout-info {
  color: $color-bosch-light-blue;
  background-color: adjust-color($color-bosch-light-blue, $alpha: -0.85);

  #{$specific-hover-selector} {
    color: $color-blue-light-lochmara--pastell;
  }
}

.rb-callout-success {
  color: $color-bosch-light-green;
  background-color: adjust-color($color-bosch-light-green, $alpha: -0.85);

  #{$specific-hover-selector} {
    color: $color-green-lima--pastell;
  }
}
