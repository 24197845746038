$day-color: var(--bosch-gray-25);

.flatpickr-calendar {
  border-radius: 0;

  &.inline {
    top: 0;
  }
}

.flatpickr-wrapper {
  display: block;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: var(--major-accent__enabled__fill__default);
  color: white;
  border-color: var(--major-accent__enabled__fill__default);
}

// do not show any background or border during selection, hard to sync between the pickers
.flatpickr-day.inRange {
  background: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background-color: transparent !important;
  color: $day-color !important;
  border-color: transparent !important;
}

.flatpickr-day.day-in-range {
  box-shadow: -5px 0 0 var(--minor-accent__enabled__fill__hovered), 5px 0 0 var(--minor-accent__enabled__fill__hovered);
  background: var(--minor-accent__enabled__fill__hovered) !important;
  border-color: var(--minor-accent__enabled__fill__hovered) !important;
  border-radius: 0 !important;
  color: $day-color !important;
}

// this will apply the correct style when you select a same day range, like 02-02-2022 to 02-02-2022
.flatpickr-day.selected.same-day-range {
  border-radius: 150px !important;
  box-shadow: none;
  background-color: var(--major-accent__enabled__fill__default);
  color: white;
  border-color: var(--major-accent__enabled__fill__default);
}

// show gray circle when hover over a day not part of range
.flatpickr-day:hover:not(.day-in-range):not(.selected),
.flatpickr-day:focus:not(.day-in-range):not(.selected) {
  cursor: pointer;
  outline: 0;
  background-color: var(--bosch-gray-90) !important;
  border-color: var(--bosch-gray-90);
  color: $day-color;
  border-radius: 150px;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: none;
}

.date-range-picker {
  .start-calendar .flatpickr-day.selected.start-range,
  .end-calendar .flatpickr-day.selected.start-range {
    border-radius: 50px 0 0 50px !important;
    background: var(--major-accent__enabled__fill__default) !important;
    box-shadow: none;
    color: white !important;
    border-color: var(--major-accent__enabled__fill__default) !important;
  }
  .start-calendar .flatpickr-day.selected.end-range,
  .end-calendar .flatpickr-day.selected.end-range {
    border-radius: 0 50px 50px 0 !important;
    background: var(--major-accent__enabled__fill__default) !important;
    box-shadow: none;
    color: white !important;
    border-color: var(--major-accent__enabled__fill__default) !important;
  }
}

.flatpickr-current-month {
  .numInputWrapper {
    margin-right: 0.5rem;
  }

  .flatpickr-monthDropdown-months {
    margin: -1px 1.5rem 0 0rem;
    padding: 0;
    transition: background 100ms ease-in-out;
  }
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--major-accent__enabled__fill__default);
}

.hidden-arrow {
  visibility: hidden;
}

.picker-calendars {
  .flatpickr-day {
    &.prevMonthDay,
    &.nextMonthDay {
      visibility: hidden;
    }
  }
}
