@use 'sass:math';

// copied from basics.scss as long as frok is not included directly
@function rem($px, $base: 16px) {
  @return math.div($px, $base) * 1rem;
}

.basic-list {
  padding-left: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

.link-list {
  padding-left: 0;
  margin: 0;

  .link-list-item,
  li {
    list-style: none;
    padding: 0;
    margin: 0;

    &:before {
      display: none;
    }

    a {
      display: block;
      padding: map-get($spacing-vertical, s) $default-spacing;
      font-size: map-get($font-sizes, smaller);
      color: $color-gray-solid;

      &.active {
        color: $color-black-pitch-dark;
      }

      &:hover {
        background-color: $color-gray-alabaster;
        color: $navigation-link-color--active;
        text-decoration: none;
      }
    }
  }
}

a {
  text-decoration: none;
}

a.open,
a:hover {
  > rb-icon {
    fill: $navigation-link-color--active;
  }
}
.rb-dropdown-toggle {
  outline: 0;
}

.rb-dropdown-toggle::after {
  // rb-ic-forward-right
  content: '\e181';
  font-family: 'Bosch-Icon';
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
  transform: rotate(90deg);
  display: inline-block;
  margin-left: 0.5em;
}

.rb-dropdown-toggle.open::after {
  transform: rotate(270deg);
  transition: transform $ease-in-out-quint $transition-duration-s;
}

.rb-details-toggle::before {
  // rb-ic-forward-right
  content: '\e181';
  font-family: 'Bosch-Icon';
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 100%;
  vertical-align: middle;
  transform: rotate(90deg);
  display: inline-block;
  margin-right: 0.5em;
}

.rb-details-toggle.open::before {
  transform: rotate(270deg);
  transition: transform $ease-in-out-quint $transition-duration-s;
}

.rb-btn.rb-details-toggle::before {
  margin-left: -0.5em;
}

rb-icon.small {
  font-size: 80%;
}

.content {
  margin: $default-spacing 0;
}

.spacing {
  margin: $default-spacing;
}

.negate-spacing {
  margin: -$default-spacing;
}

.negate-spacing-h {
  margin-left: -$default-spacing;
  margin-right: -$default-spacing;
}

.negate-spacing-v {
  margin-top: -$default-spacing;
  margin-bottom: -$default-spacing;
}

.rb-link {
  &.rb-forward::after,
  &.rb-backward::before {
    transition: transform 400ms cubic-bezier(0.38, 0.04, 0.35, 0.96);
    display: inline-block;
  }

  &.rb-forward::after {
    content: '❭';
    margin-left: 5px;
  }

  &.rb-backward::before {
    content: '❬';
    margin-right: 5px;
  }

  &:hover {
    &.rb-forward::after {
      transform: translateX(3px);
    }

    &.rb-backward::before {
      transform: translateX(-3px);
    }
  }

  &.rb-secondary {
    color: $color-black;

    &:hover {
      color: $color-bosch-light-blue-w50;
    }

    &:active {
      color: $color-bosch-light-blue;
    }

    &:disabled,
    &.disabled {
      color: $color-bosch-light-gray;
    }
  }
}

.-clickable {
  cursor: pointer;
}
