rb-form-input,
rb-form-date-input,
rb-form-date-range-input,
rb-form-textarea,
rb-form-select,
rb-form-file,
rb-form-multi-select,
rb-form-custom-select,
rb-form-chips-input,
.rb-custom-input {
  display: block;

  // Extensions for the text field
  .a-text-field {
    .a-text-field__icon {
      background-position: 80% 0;
      width: 24px;
      height: 24px;
      pointer-events: none;
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      display: inline-block;
      font-size: 24px;
    }

    &.-error input {
      padding-right: 1rem;
    }

    input.with-icon {
      padding-right: 3rem;
    }
  }

  .a-text-area {
    height: auto;
    line-height: 0;
    background-color: var(--neutral__enabled__fill__default);

    &:hover {
      background-color: var(--neutral__enabled__fill__hovered);

      textarea {
        background-color: var(--neutral__enabled__fill__hovered);
      }
    }

    &.-focus {
      background-color: var(--neutral__focused__fill__default);

      textarea {
        background-color: var(--neutral__focused__fill__default);
      }
    }

    textarea {
      resize: vertical;
    }
  }

  &.-small .a-text-area textarea {
    height: 5rem;
    min-height: 5rem;
  }

  &.small-input {
    .a-text-field.no-label,
    .a-dropdown.no-label {
      input,
      select,
      .rb-select-btn {
        height: 2.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }

      textarea {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        min-height: 2.5rem;
      }
    }
  }

  .rb-select-btn {
    text-align: left;
    background-color: var(--neutral__enabled__fill__default);
    color: var(--neutral__enabled__text_default);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    border-bottom: rem(1px) solid var(--neutral__enabled__front__default);
    height: rem(48px);
    padding: 0 2.75rem 0 1rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.rb-file-select {
      margin: 0;
      position: static;
      font-size: inherit;
      display: block;
      max-width: unset;
      padding-top: 1.25rem;
    }

    &:hover {
      background-color: var(--neutral__enabled__fill__hovered);
    }

    &:active {
      background-color: var(--neutral__enabled__fill__pressed);
      outline: none;
    }
  }

  label + .rb-select-btn {
    padding-top: rem(18px);
    padding-bottom: rem(5px);
  }

  .file-input {
    display: none;
  }

  &.static {
    .m-form-field {
      margin: 0;
    }
  }

  .a-text-field--disabled,
  .a-dropdown--disabled {
    pointer-events: none;

    label,
    &::placeholder,
    input,
    textarea,
    select,
    .rb-ic,
    .rb-select-btn {
      color: var(--neutral__disabled__front__default);
    }
  }
}

rb-form-errors .error-messages {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  margin-left: 1rem;
  color: var(--bosch-red-50);
}

.list-item-input {
  margin: 0.5rem;
  display: block;
  line-height: 1rem;

  .m-form-field {
    margin: 0;
  }
}

rb-date-range-picker {
  .picker-calendars {
    display: flex;

    .end-date {
      margin-left: 1rem;
    }
  }

  .flatpickr-calendar {
    box-shadow: none;
  }

  .picker-relative {
    margin-top: 1rem;
  }
}

.dropdown rb-date-range-picker .tab-panel rb-navigation nav > a:first-child {
  margin-left: 1em;
}

.dropdown rb-date-range-picker .tab-panel rb-navigation nav > a:last-child {
  margin-right: 1em;
}

rb-form-relative-time-input {
  .relative-time-form {
    display: flex;
  }

  .relative-time-form-number {
    width: 6rem;
    //margin: 0 0.5rem;
    input {
      text-align: right;
    }
  }
}

.rb-combined-input {
  > label {
    position: absolute;
    margin: rem(4px) rem(16px) auto rem(16px);
    font-size: rem(12px);
    max-width: calc(100% - 2.25rem);
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
    z-index: 1;
  }

  .rb-combined-input-form {
    display: flex;
  }
}

.cursor-pointer {
  cursor: pointer;
  pointer-events: auto !important;
}
