$rb-switch-spacing: 8px;
$rb-switch-label-size: map-get($font-sizes, small);

.rb-switch {
  display: inline-block;
  height: 24px;
  max-width: 100%;
  line-height: 24px;
  white-space: nowrap;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:not(.rb-switch-disabled) .rb-switch-bar:hover .rb-switch-radius-element {
    opacity: 0.06;
  }

  &.rb-switch-disabled.rb-switch-checked .rb-switch-bar {
    background: rgba($color-bosch-dark-blue, 0.4);
  }

  &.rb-switch-checked .rb-switch-bar {
    background-color: $color-bosch-dark-blue;
  }

  &.rb-switch-disabled {
    .rb-switch-label,
    .rb-switch-thumb-container {
      cursor: default;
      color: $color-bosch-light-gray;
    }
  }

  &.rb-switch-checked .rb-switch-thumb-container {
    transform: translateX(24px);
  }

  &.rb-switch-small {
    .rb-switch-bar {
      height: 18px;
      width: 36px;
      background-color: $color-bosch-gray-80;
    }
    .rb-switch-thumb-container {
      width: 10px;
      height: 10px;
      top: 4px;
      left: 4px;
      .rb-switch-thumb {
        width: 10px;
        height: 10px;
        background-color: $color-black;
      }
      .rb-switch-radius {
        top: calc(50% - 10px);
        left: calc(50% - 10px);
        height: 20px;
        width: 20px;
      }
    }

    &.rb-switch-checked .rb-switch-thumb-container {
      transform: translateX(18px);
      .rb-switch-thumb {
        background-color: $color-white;
      }
    }
    &.rb-switch-checked .rb-switch-bar {
      background-color: $color-bosch-blue-50;
    }
  }

  label.rb-switch-label {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    height: inherit;
    cursor: pointer;
    font-size: $rb-switch-label-size;
  }

  .rb-switch-label-before {
    .rb-switch-label {
      order: 1;
    }
    .rb-switch-bar {
      order: 2;
      margin-right: 0;
      margin-left: $rb-switch-spacing;
    }
  }

  .rb-switch-bar {
    width: 48px;
    height: 24px;
    margin-right: $rb-switch-spacing;
    margin-left: 0;
    border-radius: 12px;
    background-color: $color-bosch-light-gray-w50;
    transition: 0.2s;
    position: relative;
    flex-shrink: 0;
  }

  .rb-switch-input {
    bottom: 0;
    left: 10px;

    &.switch-hidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      outline: 0;
      -webkit-appearance: none;
    }
  }

  .rb-switch-thumb-container {
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    position: absolute;
    z-index: 1;
    transition: 0.2s;
    transform: translate3d(0, 0, 0);
    transition-property: transform;
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: grab;

    .rb-switch-thumb {
      width: 20px;
      height: 20px;
      box-shadow: none;
      border-radius: 50%;
      background-color: white;
    }

    .rb-switch-radius {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      height: 40px;
      width: 40px;
      z-index: 1;
      pointer-events: none;
      overflow: hidden;
    }

    .rb-switch-radius-element {
      background-color: $color-bosch-dark-blue;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      border-radius: 50%;
      pointer-events: none;
      transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
      transform: none;
    }
  }
}
