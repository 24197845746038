.teaser-panel {
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  margin: map-get($spacing-vertical, s) * -1;

  &.teaser-panel-small {
    grid-template-columns: repeat(2, 1fr);
  }

  &.teaser-panel-medium {
    grid-template-columns: repeat(1, 1fr);
  }

  &.teaser-panel-large {
    grid-template-columns: repeat(1, 1fr);
  }
}

rb-teaser-panel-item {
  display: flex;
  flex-direction: column;
  padding: map-get($spacing-vertical, s);

  .item-bottom {
    padding-top: map-get($spacing-vertical, s);
    margin-top: auto;
  }

  h2,
  h3,
  h4 {
    margin-bottom: map-get($spacing-vertical, s);
  }

  &.centered {
    justify-content: center;
    text-align: center;
  }
}

rb-teaser-panel.hover-shade rb-teaser-panel-item:hover {
  background-color: $color-gray-alabaster;
}

// larger sm
@media (min-width: map-get($rb-grid-breakpoints, sm)) {
  .teaser-panel {
    margin: map-get($spacing-vertical, m) * -1;
    rb-teaser-panel-item {
      padding: map-get($spacing-vertical, m);
    }
    &.teaser-panel-small {
      grid-template-columns: repeat(3, 1fr);
    }

    &.teaser-panel-medium {
      grid-template-columns: repeat(2, 1fr);
    }

    &.teaser-panel-large {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

// larger md
@media (min-width: map-get($rb-grid-breakpoints, md)) {
  .teaser-panel {
    margin: map-get($spacing-vertical, l) * -1;
    rb-teaser-panel-item {
      padding: map-get($spacing-vertical, l);
    }
    &.teaser-panel-small {
      grid-template-columns: repeat(4, 1fr);
    }

    &.teaser-panel-medium {
      grid-template-columns: repeat(3, 1fr);
    }

    &.teaser-panel-large {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

// larger lg
@media (min-width: map-get($rb-grid-breakpoints, lg)) {
  .container-full {
    .teaser-panel {
      &.teaser-panel-small {
        grid-template-columns: repeat(5, 1fr);
      }

      &.teaser-panel-medium {
        grid-template-columns: repeat(4, 1fr);
      }

      &.teaser-panel-large {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

// larger xl
@media (min-width: map-get($rb-grid-breakpoints, xl)) {
  .container-full {
    .teaser-panel {
      &.teaser-panel-small {
        grid-template-columns: repeat(6, 1fr);
      }

      &.teaser-panel-medium {
        grid-template-columns: repeat(5, 1fr);
      }

      &.teaser-panel-large {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
