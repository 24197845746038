@use 'sass:math';
// ==========================================================================
// Definition of typography
// ==========================================================================

//
// PX to Rem transform settings
// ==========================================================================

$base-font-size--unitless: 16;
$base-font-size: $base-font-size--unitless * 1px;
$relative-pixels: math.div(1, $base-font-size--unitless); // = .0625
$rem-pixels: $relative-pixels * 1rem;

//
// Sizes
// ==========================================================================

$line-heights: (
  mini: 0.5,
  smaller: 1,
  small: 1.2,
  medium: 1.4,
  big: 1.5,
  bigger: 2,
  biggest: 2.2,
  giga: 2.5
);

$font-weights: (
  bold: 700,
  medium: 600,
  normal: 400,
  light: 200
);

$font-sizes: (
  micro: 8 * $rem-pixels,
  mini: 10 * $rem-pixels,
  smallest: 12 * $rem-pixels,
  smaller: 14 * $rem-pixels,
  small: 16 * $rem-pixels,
  medium: 18 * $rem-pixels,
  big: 20 * $rem-pixels,
  bigger: 22 * $rem-pixels,
  biggest: 24 * $rem-pixels,
  giga: 50 * $rem-pixels
);

$king-font-sizes: (
  superhigh: 200 * $rem-pixels,
  mega: 150 * $rem-pixels,
  giga: 116 * $rem-pixels,
  tall: 92 * $rem-pixels,
  bigger: 72 * $rem-pixels,
  big: 60 * $rem-pixels,
  medium: 48 * $rem-pixels,
  small: 40 * $rem-pixels,
  smaller: 38 * $rem-pixels,
  smallest: 34 * $rem-pixels,
  micro: 30 * $rem-pixels
);

$heading-font-sizes: (
  h1-md: 42 * $rem-pixels,
  h1-sm: 34 * $rem-pixels,
  h1-xs: 30 * $rem-pixels,
  h2-md: 34 * $rem-pixels,
  h2-sm: 26 * $rem-pixels,
  h2-xs: 26 * $rem-pixels,
  h3-md: 26 * $rem-pixels,
  h3-sm: 22 * $rem-pixels,
  h3-xs: 22 * $rem-pixels,
  h4-md: 22 * $rem-pixels,
  h4-sm: 18 * $rem-pixels,
  h4-xs: 18 * $rem-pixels,
  h5-md: 18 * $rem-pixels,
  h5-sm: 18 * $rem-pixels,
  h5-xs: 14 * $rem-pixels,
  h6-md: 14 * $rem-pixels,
  h6-sm: 14 * $rem-pixels,
  h6-xs: 14 * $rem-pixels
);

$quotation-mark-font-sizes: (
  md: 65 * $rem-pixels,
  sm: 53 * $rem-pixels,
  xs: 45 * $rem-pixels
);

// ==========================================================================
// Functional typography definitions
// ==========================================================================

$base-font-size: map-get($font-sizes, small);
$base-font-family--initial: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-font-family--lazyloaded: 'boschsans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-font-family--bold: 'Bosch Sans Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-line-height: map-get($line-heights, big);

$headline-font-weight: map-get($font-weights, light);
$headline-line-height: map-get($line-heights, small);

$caption-font-size: map-get($font-sizes, smaller);
$caption-line-height: map-get($line-heights, medium);

$copy-line-height: $base-line-height;
$copy-line-height--big: map-get($line-heights, medium);
$copy-line-height--medium: map-get($line-heights, small);

$copy-font-size: map-get($font-sizes, smaller);
$copy-font-size--sm: map-get($font-sizes, small);
$copy-font-size--md: map-get($font-sizes, small);

$copy-font-size--large: map-get($king-font-sizes, small);
$copy-font-size--large--sm: map-get($king-font-sizes, medium);
$copy-font-size--large--md: map-get($king-font-sizes, big);

$copy-font-size--big: map-get($font-sizes, medium);
$copy-font-size--medium: map-get($font-sizes, small);
$copy-font-size--small: map-get($font-sizes, smaller);

$copy-font-weight: map-get($font-weights, light);
$copy-font-weight--md: map-get($font-weights, normal);
$copy-font-weight--large: map-get($font-weights, light);
$copy-font-weight--big: map-get($font-weights, normal);
$copy-font-weight--small: map-get($font-weights, light);

$copy-text-margin-bottom--large: map-get($spacing-vertical--xs, m);
$copy-text-margin-bottom--large--md: map-get($spacing-vertical--md, m);
