// ==========================================================================
// Brand colors of Bosch
// ==========================================================================

// The approach to name colors in Bosch is as follows:
// First we define variables for the colors by calling them
// after their name.
// Naming convention:
// $color-{what-color-is-it}-{name-of-the-shade}
// All the color names chosen in this file are based on this theory:
// https://davidwalsh.name/sass-color-variables-dont-suck

// ==========================================================================
// Main colors
// ==========================================================================

// Colors from brandguide generated with node tools/colors.js
$color-bosch-red: #ea0016; // Bosch Red
$color-bosch-fuchsia: #b90276; // Bosch Fuchsia
$color-bosch-violet: #50237f; // Bosch Violet
$color-bosch-dark-blue: #005691; // Bosch Dark Blue
$color-bosch-light-blue: #008ecf; // Bosch Light Blue
$color-bosch-turquoise: #00a8b0; // Bosch Turquoise
$color-bosch-light-green: #78be20; // Bosch Light Green
$color-bosch-dark-green: #006249; // Bosch Dark Green
$color-black: #000000; // Black
$color-bosch-dark-gray: #525f6b; // Bosch Dark Gray
$color-bosch-light-gray: #bfc0c2; // Bosch Light Gray
$color-white: #ffffff; // White
$color-bosch-red-w75: #fabfc5; // Bosch Red W75
$color-bosch-red-w50: #f4808b; // Bosch Red W50
$color-bosch-red-w25: #ef4050; // Bosch Red W25
$color-bosch-red: #ea0016; // Bosch Red
$color-bosch-red-b25: #b00010; // Bosch Red B25
$color-bosch-red-b50: #75000b; // Bosch Red B50
$color-bosch-red-b75: #3a0006; // Bosch Red B75
$color-bosch-fuchsia-w75: #edc0dd; // Bosch Fuchsia W75
$color-bosch-fuchsia-w50: #dc80ba; // Bosch Fuchsia W50
$color-bosch-fuchsia-w25: #cb4198; // Bosch Fuchsia W25
$color-bosch-fuchsia: #b90276; // Bosch Fuchsia
$color-bosch-fuchsia-b25: #8b0158; // Bosch Fuchsia B25
$color-bosch-fuchsia-b50: #5d013b; // Bosch Fuchsia B50
$color-bosch-fuchsia-b75: #2e011e; // Bosch Fuchsia B75
$color-bosch-violet-w75: #d3c8df; // Bosch Violet W75
$color-bosch-violet-w50: #a791bf; // Bosch Violet W50
$color-bosch-violet-w25: #7c5a9f; // Bosch Violet W25
$color-bosch-violet: #50237f; // Bosch Violet
$color-bosch-violet-b25: #3c1a5f; // Bosch Violet B25
$color-bosch-violet-b50: #281240; // Bosch Violet B50
$color-bosch-violet-b75: #140920; // Bosch Violet B75
$color-bosch-dark-blue-w75: #bfd5e3; // Bosch Dark Blue W75
$color-bosch-dark-blue-w50: #7faac8; // Bosch Dark Blue W50
$color-bosch-dark-blue-w25: #4080ad; // Bosch Dark Blue W25
$color-bosch-dark-blue: #005691; // Bosch Dark Blue
$color-bosch-dark-blue-b25: #00406d; // Bosch Dark Blue B25
$color-bosch-dark-blue-b50: #002b49; // Bosch Dark Blue B50
$color-bosch-dark-blue-b75: #001624; // Bosch Dark Blue B75
$color-bosch-light-blue-w75: #bfe3f3; // Bosch Light Blue W75
$color-bosch-light-blue-w50: #7fc6e7; // Bosch Light Blue W50
$color-bosch-light-blue-w25: #40aadb; // Bosch Light Blue W25
$color-bosch-light-blue: #008ecf; // Bosch Light Blue
$color-bosch-light-blue-b25: #006a9b; // Bosch Light Blue B25
$color-bosch-light-blue-b50: #004768; // Bosch Light Blue B50
$color-bosch-light-blue-b75: #002434; // Bosch Light Blue B75
$color-bosch-turquoise-w75: #bfe9eb; // Bosch Turquoise W75
$color-bosch-turquoise-w50: #7fd3d7; // Bosch Turquoise W50
$color-bosch-turquoise-w25: #40bec4; // Bosch Turquoise W25
$color-bosch-turquoise: #00a8b0; // Bosch Turquoise
$color-bosch-turquoise-b25: #007e84; // Bosch Turquoise B25
$color-bosch-turquoise-b50: #005458; // Bosch Turquoise B50
$color-bosch-turquoise-b75: #002a2c; // Bosch Turquoise B75
$color-bosch-light-green-w75: #ddefc7; // Bosch Light Green W75
$color-bosch-light-green-w50: #bbde8f; // Bosch Light Green W50
$color-bosch-light-green-w25: #9ace58; // Bosch Light Green W25
$color-bosch-light-green: #78be20; // Bosch Light Green
$color-bosch-light-green-b25: #5a8e18; // Bosch Light Green B25
$color-bosch-light-green-b50: #3c5f10; // Bosch Light Green B50
$color-bosch-light-green-b75: #1e3008; // Bosch Light Green B75
$color-bosch-dark-green-w75: #bfd8d1; // Bosch Dark Green W75
$color-bosch-dark-green-w50: #7fb0a4; // Bosch Dark Green W50
$color-bosch-dark-green-w25: #408977; // Bosch Dark Green W25
$color-bosch-dark-green: #006249; // Bosch Dark Green
$color-bosch-dark-green-b25: #004937; // Bosch Dark Green B25
$color-bosch-dark-green-b50: #003125; // Bosch Dark Green B50
$color-bosch-dark-green-b75: #001912; // Bosch Dark Green B75
$color-bosch-light-gray-w75: #efeff0; // Bosch Light Gray W75
$color-bosch-light-gray-w50: #dfdfe0; // Bosch Light Gray W50
$color-bosch-light-gray-w25: #cfd0d1; // Bosch Light Gray W25
$color-bosch-light-gray: #bfc0c2; // Bosch Light Gray
$color-bosch-light-gray-b25: #8f9091; // Bosch Light Gray B25
$color-bosch-light-gray-b50: #606061; // Bosch Light Gray B50
$color-bosch-light-gray-b75: #303031; // Bosch Light Gray B75
$color-bosch-dark-gray-w75: #d4d7da; // Bosch Dark Gray W75
$color-bosch-dark-gray-w50: #a8afb5; // Bosch Dark Gray W50
$color-bosch-dark-gray-w25: #7d8790; // Bosch Dark Gray W25
$color-bosch-dark-gray: #525f6b; // Bosch Dark Gray
$color-bosch-dark-gray-b25: #3d4750; // Bosch Dark Gray B25
$color-bosch-dark-gray-b50: #293036; // Bosch Dark Gray B50
$color-bosch-dark-gray-b75: #15181b; // Bosch Dark Gray B75
$color-bosch-yellow-w75: #feebc5; // Bosch Yellow W75
$color-bosch-yellow-w50: #fdd78b; // Bosch Yellow W50
$color-bosch-yellow-w25: #fdc351; // Bosch Yellow W25
$color-bosch-yellow: #fcaf17; // Bosch Yellow
$color-bosch-yellow-b25: #bd8311; // Bosch Yellow B25
$color-bosch-yellow-b50: #7e580c; // Bosch Yellow B50
$color-bosch-yellow-b75: #3f2c06; // Bosch Yellow B75

//
// Main Colors defined in the brand
// ==========================================================================

$color-green-tropical-rain-forest: #006249; // Bosch Dark Green
$color-green-lima: #78be20; // Bosch Light Green
$color-red-monza: #ea0016; // Bosch Red
$color-fuchsia-lipstick: #b90276; // Bosch Fuchsia
$color-violet-meteorite: #50237f; // Bosch Violet
$color-blue-dark-bahama-blue: #005691; // Bosch Dark Blue
$color-blue-dark-matisse: #1a679c;
$color-blue-light-lochmara: #008ecf; // Bosch Light Blue
$color-turquoise-bondi-blue: #00a8b0; // Bosch Turquoise
$color-yellow-sunlight: #fcaf17; // Bosch Yellow (Warning)

//
// Light version of all the main colors
// ==========================================================================

$color-green-tropical-rain-forest--pastell: #7fb0a4;
$color-green-lima--pastell: #bbde8f;
$color-red-monza--pastell: #f07f8a;
$color-fuchsia-lipstick--pastell: #dc80ba;
$color-violet-meteorite--pastell: #a791bf;
$color-blue-dark-bahama-blue--pastell: #7faac8;
$color-blue-light-lochmara--pastell: #7fc6e7;
$color-turquoise-bondi-blue--pastell: #7fd3d7;
$color-blue-tropical--pastell: #ccebf8;
$color-blue-botticelli--pastell: #cce0eb;

// ==========================================================================
// Support colors
// ==========================================================================

//
// Main support colors
// ==========================================================================

$color-gray-shuttle: #525f6b; // Bosch Dark Gray
$color-gray-silver-sand: #bfc0c2; // Bosch Light Gray
$color-black-pitch-dark: #000000;
$color-white-paper-white: #ffffff;
$color-gray-solid: #808080;
$color-gray-athens: #fafbfc;
$color-gray-alabaster: #f7f7f7;
$color-gray-mercury: #e6e6e6;
$color-gray-alto-light: #dddddd;
$color-gray-alto: #d9d9d9;
$color-gray-silver: #cccccc;
$color-gray-tundora: #4d4d4d;
$color-gray-mine-shaft: #333333;
$color-gray-moon: #b7b7b7;
$color-gray-depression: #d4d7da;
$color-gray-gallery: #f0f0f0;
$color-gray-londonfog: #7d8790;
$color-gray-iron: #cfd0d1;

//
// Variations of the support colors
// ==========================================================================

$color-gray-shuttle--pastell: #a8afb5;
$color-gray-silver-sand--pastell: #dfdfe0;
$color-black-pitch-dark--pastell: $color-black-pitch-dark;
$color-white-paper-white--pastell: #ffffff;

// ==========================================================================
// Bootstrap color variables
// ==========================================================================

// After having defined the colors we will use in the brand
// and having name them after their color name and name of the shade
// we define the funtional colors. These are the variables we will use
// along the project
// Most of these variables names are taken from Bootstrap and are used there
// Hence the suboptimal titles for the gray-shades, should not be edited

// Note: These color names are following Bootstrap naming conventions.
// Which are indeed against our pattern
$brand-primary: $color-bosch-dark-blue;
$brand-success: $color-bosch-light-green;
$brand-info: $color-bosch-light-blue;
$brand-warning: $color-bosch-yellow;
$brand-danger: $color-bosch-red;

$gray-base: $color-black-pitch-dark;
$gray-darker: lighten($gray-base, 13.5%);
$gray-dark: lighten($gray-base, 20%);
$gray: lighten($gray-base, 33.5%);
$gray-light: lighten($gray-base, 46.7%);
$gray-lighter: lighten($gray-base, 93.5%);

// ==========================================================================
// Functional colors
// ==========================================================================

$background-color-base: $color-white-paper-white;
$main-content-background-color: $color-gray-alabaster;
$font-color-base: $color-black-pitch-dark;
$font-color-base--inverted: $color-white-paper-white;
$component-background-color--active: $color-gray-alabaster;
$component-divider-color: $color-gray-mercury;
$component-text-inactive: $color-gray-solid;
$preload-box-background-color: $color-gray-alabaster;

//
// Links
// ==========================================================================

$link-color--primary: $brand-primary;
$link-color--secondary: $color-gray-silver-sand;
$link-color--inverted: $color-white-paper-white;

$link-color--primary--hover: $color-bosch-light-blue-w50;
$link-color--secondary--hover: $color-bosch-light-blue-w50;
$link-color--inverted--hover: $color-gray-silver-sand;

$navigation-link-color--active: $color-blue-dark-bahama-blue;

//
// Colors for the gradients
// ==========================================================================

$gradient-pair-variations: (
  fuchsia-violet: (
    $color-fuchsia-lipstick,
    $color-violet-meteorite
  ),
  red-fuchsia: (
    $color-red-monza,
    $color-fuchsia-lipstick
  ),
  violet-darkBlue: (
    $color-violet-meteorite,
    $color-blue-dark-bahama-blue
  ),
  darkBlue-lightBlue: (
    $color-blue-dark-bahama-blue,
    $color-blue-light-lochmara
  ),
  lightBlue-turquoise: (
    $color-blue-light-lochmara,
    $color-turquoise-bondi-blue
  ),
  turquoise-lightGreen: (
    $color-turquoise-bondi-blue,
    $color-green-lima
  ),
  lightGreen-darkGreen: (
    $color-green-lima,
    $color-green-tropical-rain-forest
  )
);

//
// Special rgba settings for the custom webkit scrollbar
// ==========================================================================

$custom-scrollbar-webkit-track: rgba(0, 0, 0, 0.1);
$custom-scrollbar-webkit-thumb: rgba(0, 0, 0, 0.2);
$custom-scrollbar-webkit-thumb--hover: rgba(0, 0, 0, 0.4);
$custom-scrollbar-webkit-thumb--window-inactive: rgba(0, 0, 0, 0.05);

//
// Social network colors for social post molecule
// ==========================================================================

// source: https://de.facebookbrand.com/assets
$socialpost-color-facebook: #3b5998;

// source: https://www.youtube.com/yt/brand/color.html
$socialpost-color-youtube: #e52d27;

// source (for lack of any official guideline): https://brandcolors.net/b/google-plus
$socialpost-color-googleplus: #dd4b39;

// source: https://brand.twitter.com/de.html
$socialpost-color-twitter: #1da1f3;

// source: https://www.tumblr.com/logo
$socialpost-color-tumblr: #35465c;

// source: https://business.pinterest.com/de/brand-guidelines
$socialpost-color-pinterest: #bd081c;

// source: https://en.instagram-brand.com/assets/glyph-icon =>
//         https://en.instagram-brand.com/wp-content/uploads/2016/11/04.png?w=560&h=188
$socialpost-color-instagram: #d93175;
