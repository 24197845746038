rb-accordion-title {
  .accordion-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    padding: map-get($spacing-vertical, m) 0;
    border-top: 1px solid $color-gray-mercury;

    & > h5 {
      margin: 0;
    }
  }
  .accordion-toggle {
    font-size: 2rem;
    line-height: 1em;
    display: flex;
  }
  .accordion-icon-part1 {
    width: 100%;
  }
  .accordion-icon-part2 {
    width: 100%;
    margin-left: -100%;
  }

  &:last-of-type .accordion-row {
    border-bottom: 1px solid $color-gray-mercury;
    &.open {
      border-bottom: 1px solid transparent;
    }
  }
}

rb-accordion-body {
  .accordion-body {
    padding-bottom: map-get($spacing-vertical, m);
    &.open {
    }
  }
  &:last-of-type .accordion-body {
    &.open {
      border-bottom: 1px solid $color-gray-mercury;
    }
  }
  .accordion-body-container {
    overflow: hidden;
    display: block;
  }
}
