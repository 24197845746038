.--body-scroll .o-minimal-header .o-minimal-header__basic-actions {
  overflow: visible;
}

.o-minimal-header {
  position: relative;
  z-index: unset;
  padding-top: 0;
  background-color: white;
  justify-content: space-between;
  height: auto;
  box-sizing: content-box;

  .-hidden {
    visibility: hidden;
    position: absolute;
  }

  .o-context-menu {
    height: 100%;
  }

  .a-button {
    &:focus-visible {
      outline: none;
    }

    height: 100%;
    display: flex;
    align-items: center;

    .a-button__icon {
      padding: 0.75rem;
    }
  }

  .o-minimal-header__burger button.--selected {
    background-color: var(--bosch-gray-95);
  }

  .o-minimal-header__title-actions {
    display: flex;
    flex: none;
  }

  .o-minimal-header__spacer {
    flex: auto;
  }

  .o-minimal-header__basic-actions {
    display: flex;

    .o-minimal-header__actions {
      display: flex;
      margin-left: 1rem;
      margin-right: 1rem;

      li.action {
        position: static;
        display: flex;
        align-items: center;

        .title {
          white-space: nowrap;
          padding: 0.5rem;
        }
      }
    }

    .a-button__label {
      display: block;
    }

    &.-scrollable {
      overflow-x: auto;
      overflow-y: visible;
    }
  }

  .rb-app-minimal-header-menu {
    top: 3.5rem;
    right: 0.25rem;

    .a-box:after,
    .a-box:before {
      right: 0.25rem;
    }
  }

  .rb-app-minimal-header-user-menu_no-button {
    display: flex;
  }

  .o-minimal-header__title {
    margin: 0;
    padding-left: 0px;
    max-width: fit-content;

    .page-title-text {
      @extend .o-minimal-header__title;
      padding-left: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      --font-size: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.5;
      min-width: unset;
    }

    .a-button {
      max-width: 100%;
      justify-content: flex-start;
      flex: 0 1 auto;

      .page-title-text {
        flex: 0 1 auto;
      }
      .a-icon {
        flex: none;
      }
    }

    .page-title-with-context {
      display: flex;
      align-items: center;
    }
  }

  .rb-app-minimal-header-avatar {
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    border-radius: 50%;
  }

  .action-template-container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .o-minimal-header__logo {
    display: block;
    width: auto;

    a {
      display: block;
      height: 100%;
    }

    img {
      height: 100%;
    }
  }
}
