rb-form-checkbox,
rb-form-multi-checkbox {
  .a-checkbox {
    height: auto;

    .m-form-field-checkbox-label {
      background-color: initial;
    }
  }

  .a-checkbox.stateFalse input[type='checkbox']:checked + label:after {
    font-family: Bosch-UI-Icon;
    font-size: 1.5rem;
    line-height: 1;
    height: 1.5rem;
    width: 1.5rem;
    color: var(--major-accent__enabled__front__default);
    position: absolute;
    left: 0;
    content: var(--ui-ic-close);
  }

  &.static {
    .m-form-field {
      margin: 0;
    }
  }
}
