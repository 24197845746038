// Brand guide: https://brandguide.bosch.com/document/448/en#/digital-basic-elements/colors
// Colors from brandguide generated with node tools/colors.js
$color-black: #000000; // Black
$color-bosch-gray-50: #71767c; // Bosch Gray 50
$color-white: #ffffff; // White
$color-bosch-red-50: #ed0007; // Bosch Red 50
$color-bosch-purple-40: #9e2896; // Bosch Purple 40
$color-bosch-blue-50: #007bc0; // Bosch Blue 50
$color-bosch-turquoise-50: #18837e; // Bosch Turquoise 50
$color-bosch-green-50: #00884a; // Bosch Green 50
$color-bosch-gray-95: #eff1f2; // Bosch Gray 95
$color-bosch-gray-90: #e0e2e5; // Bosch Gray 90
$color-bosch-gray-85: #d0d4d8; // Bosch Gray 85
$color-bosch-gray-80: #c1c7cc; // Bosch Gray 80
$color-bosch-gray-75: #b2b9c0; // Bosch Gray 75
$color-bosch-gray-70: #a4abb3; // Bosch Gray 70
$color-bosch-gray-65: #979ea4; // Bosch Gray 65
$color-bosch-gray-60: #8a9097; // Bosch Gray 60
$color-bosch-gray-55: #7d8389; // Bosch Gray 55
$color-bosch-gray-50: #71767c; // Bosch Gray 50
$color-bosch-gray-45: #656a6f; // Bosch Gray 45
$color-bosch-gray-40: #595e62; // Bosch Gray 40
$color-bosch-gray-35: #4e5256; // Bosch Gray 35
$color-bosch-gray-30: #43464a; // Bosch Gray 30
$color-bosch-gray-25: #383b3e; // Bosch Gray 25
$color-bosch-gray-20: #2e3033; // Bosch Gray 20
$color-bosch-gray-15: #232628; // Bosch Gray 15
$color-bosch-gray-10: #1a1c1d; // Bosch Gray 10
$color-bosch-gray-05: #101112; // Bosch Gray 05
$color-bosch-purple-95: #f7eef6; // Bosch Purple 95
$color-bosch-purple-90: #f0dcee; // Bosch Purple 90
$color-bosch-purple-85: #ebcae8; // Bosch Purple 85
$color-bosch-purple-80: #e8b6e3; // Bosch Purple 80
$color-bosch-purple-75: #e5a2df; // Bosch Purple 75
$color-bosch-purple-70: #e48cdd; // Bosch Purple 70
$color-bosch-purple-65: #e472db; // Bosch Purple 65
$color-bosch-purple-60: #e552da; // Bosch Purple 60
$color-bosch-purple-55: #d543cb; // Bosch Purple 55
$color-bosch-purple-50: #c535bc; // Bosch Purple 50
$color-bosch-purple-45: #b12ea9; // Bosch Purple 45
$color-bosch-purple-40: #9e2896; // Bosch Purple 40
$color-bosch-purple-35: #8b2284; // Bosch Purple 35
$color-bosch-purple-30: #791d73; // Bosch Purple 30
$color-bosch-purple-25: #671761; // Bosch Purple 25
$color-bosch-purple-20: #551151; // Bosch Purple 20
$color-bosch-purple-15: #440c41; // Bosch Purple 15
$color-bosch-purple-10: #340731; // Bosch Purple 10
$color-bosch-purple-05: #230421; // Bosch Purple 05
$color-bosch-blue-95: #e8f1ff; // Bosch Blue 95
$color-bosch-blue-90: #d1e4ff; // Bosch Blue 90
$color-bosch-blue-85: #b8d6ff; // Bosch Blue 85
$color-bosch-blue-80: #9dc9ff; // Bosch Blue 80
$color-bosch-blue-75: #7ebdff; // Bosch Blue 75
$color-bosch-blue-70: #56b0ff; // Bosch Blue 70
$color-bosch-blue-65: #00a4fd; // Bosch Blue 65
$color-bosch-blue-60: #0096e8; // Bosch Blue 60
$color-bosch-blue-55: #0088d4; // Bosch Blue 55
$color-bosch-blue-50: #007bc0; // Bosch Blue 50
$color-bosch-blue-45: #006ead; // Bosch Blue 45
$color-bosch-blue-40: #00629a; // Bosch Blue 40
$color-bosch-blue-35: #005587; // Bosch Blue 35
$color-bosch-blue-30: #004975; // Bosch Blue 30
$color-bosch-blue-25: #003e64; // Bosch Blue 25
$color-bosch-blue-20: #003253; // Bosch Blue 20
$color-bosch-blue-15: #002742; // Bosch Blue 15
$color-bosch-blue-10: #001d33; // Bosch Blue 10
$color-bosch-blue-05: #001222; // Bosch Blue 05
$color-bosch-turquoise-95: #def5f3; // Bosch Turquoise 95
$color-bosch-turquoise-90: #b6ede8; // Bosch Turquoise 90
$color-bosch-turquoise-85: #a1dfdb; // Bosch Turquoise 85
$color-bosch-turquoise-80: #8dd2cd; // Bosch Turquoise 80
$color-bosch-turquoise-75: #79c5c0; // Bosch Turquoise 75
$color-bosch-turquoise-70: #66b8b2; // Bosch Turquoise 70
$color-bosch-turquoise-65: #54aba5; // Bosch Turquoise 65
$color-bosch-turquoise-60: #419e98; // Bosch Turquoise 60
$color-bosch-turquoise-65: #2e908b; // Bosch Turquoise 65
$color-bosch-turquoise-50: #18837e; // Bosch Turquoise 50
$color-bosch-turquoise-45: #147671; // Bosch Turquoise 45
$color-bosch-turquoise-40: #116864; // Bosch Turquoise 40
$color-bosch-turquoise-35: #0e5b57; // Bosch Turquoise 35
$color-bosch-turquoise-30: #0a4f4b; // Bosch Turquoise 30
$color-bosch-turquoise-25: #07423f; // Bosch Turquoise 25
$color-bosch-turquoise-20: #053634; // Bosch Turquoise 20
$color-bosch-turquoise-15: #032b28; // Bosch Turquoise 15
$color-bosch-turquoise-10: #02201e; // Bosch Turquoise 10
$color-bosch-turquoise-05: #011413; // Bosch Turquoise 05
$color-bosch-green-95: #e2f5e7; // Bosch Green 95
$color-bosch-green-90: #b8efc9; // Bosch Green 90
$color-bosch-green-85: #9be4b3; // Bosch Green 85
$color-bosch-green-80: #86d7a2; // Bosch Green 80
$color-bosch-green-75: #72ca92; // Bosch Green 75
$color-bosch-green-70: #5ebd82; // Bosch Green 70
$color-bosch-green-65: #4ab073; // Bosch Green 65
$color-bosch-green-60: #37a264; // Bosch Green 60
$color-bosch-green-55: #219557; // Bosch Green 55
$color-bosch-green-50: #00884a; // Bosch Green 50
$color-bosch-green-45: #007a42; // Bosch Green 45
$color-bosch-green-40: #006c3a; // Bosch Green 40
$color-bosch-green-35: #005f32; // Bosch Green 35
$color-bosch-green-30: #00512a; // Bosch Green 30
$color-bosch-green-25: #004523; // Bosch Green 25
$color-bosch-green-20: #00381b; // Bosch Green 20
$color-bosch-green-15: #002c14; // Bosch Green 15
$color-bosch-green-10: #00210e; // Bosch Green 10
$color-bosch-green-05: #001507; // Bosch Green 05
$color-bosch-yellow-95: #ffefd1; // Bosch Yellow 95
$color-bosch-yellow-90: #ffdf95; // Bosch Yellow 90
$color-bosch-yellow-85: #ffcf00; // Bosch Yellow 85
$color-bosch-yellow-80: #eec100; // Bosch Yellow 80
$color-bosch-yellow-75: #deb300; // Bosch Yellow 75
$color-bosch-yellow-70: #cda600; // Bosch Yellow 70
$color-bosch-yellow-65: #bd9900; // Bosch Yellow 65
$color-bosch-yellow-60: #ad8c00; // Bosch Yellow 60
$color-bosch-yellow-55: #9e7f00; // Bosch Yellow 55
$color-bosch-yellow-50: #8f7300; // Bosch Yellow 50
$color-bosch-yellow-45: #806700; // Bosch Yellow 45
$color-bosch-yellow-40: #725b00; // Bosch Yellow 40
$color-bosch-yellow-35: #644f00; // Bosch Yellow 35
$color-bosch-yellow-30: #564400; // Bosch Yellow 30
$color-bosch-yellow-25: #493900; // Bosch Yellow 25
$color-bosch-yellow-20: #3c2e00; // Bosch Yellow 20
$color-bosch-yellow-15: #2f2400; // Bosch Yellow 15
$color-bosch-yellow-10: #231a00; // Bosch Yellow 10
$color-bosch-yellow-05: #171000; // Bosch Yellow 05
